import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Moment from 'moment';
import {withRouter} from 'react-router-dom';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {injectIntl} from 'react-intl';
import {mapSimulatorRequestData} from '../utils/offerFieldsActions';
import {
  checkOfferStatus,
  getCoversTitles,
  getAllOffers,
  getPotential,
  updatePotential,
  updatePreferredOffers,
} from '../utils/apiHandlers';
import {setSimulatorOffersFields, getSimulatorOffersFields} from '../utils/persistOfferDetails';
import cities from '../config/cities';
import {DRIVERS_COUNT} from '../constants/enums';
import Agent from '../config/agent';
import {sortingOptions} from './offers/offersSort';
import SimulatorLayout, {simulatorValidations} from './simulator/SimulatorLayout';

const offersFields = getSimulatorOffersFields();

const CHECK_STATUS_TIMEOUT = process.env.REACT_APP_CHECK_STATUS_TIMEOUT || 2000;
const CHECK_STATUS_TIME_LIMIT = sessionStorage.getItem('REACT_APP_SIMULATOR_CHECK_STATUS_TIME_LIMIT') ||
                                process.env.REACT_APP_SIMULATOR_CHECK_STATUS_TIME_LIMIT || 30000;

sessionStorage.setItem('REACT_APP_SIMULATOR_CHECK_STATUS_TIME_LIMIT', CHECK_STATUS_TIME_LIMIT);

const insuranceHistory = {
  claims: {
    injury: [],
    property: [],
  },
  insurance: [
    {type: '',
      yearsPast: 1},
    {type: '',
      yearsPast: 2},
    {type: '',
      yearsPast: 3},
    {type: '',
      yearsPast: 4},
  ],
};

const detailsStepState = Object.assign({
  agent: null,
  bodyClaimInLast3Years: '',
  bodyClaimInYear: '',
  brand: '',
  buildingNumber: '',
  city: '',
  cityCode: '',
  cityPostCode: '',
  driversCount: '',
  endDate: Moment().add(1, 'year').subtract(1, 'month').endOf('month').toDate(),
  esp: '',
  fcw: '',
  fetchingAutoInfo: false,
  firstPropClaimInYear: '',
  followupCallDate: null,
  followupPhoneNumber: '',
  hasFullInsPast: '',
  haveAnotherCar: '',
  injuryClaims_1: '',
  injuryClaims_2: '',
  injuryClaims_3: '',
  insurance_1: '',
  insurance_2: '',
  insurance_3: '',
  insuranceHistory: {...insuranceHistory},
  isSaleInProgress: null,
  isSimulator: true,
  isYoungDriverIsMain: '',
  ldw: '',
  leviModel: '',
  licenseNumber: '',
  licenseRevocation: '',
  mainDriverAge: '',
  mainDriverBirthDate: null,
  mainDriverGender: '',
  mainDriverSeniority: '',
  model: '',
  pledged: '',
  policyType: '',
  privateUCarAndOnlyPrivateUse: true,
  propClaimInLast3Years: '',
  propClaims_1: '',
  propClaims_2: '',
  propClaims_3: '',
  saturdayDriving: '',
  secondDriverAge: '',
  secondDriverBirthDate: null,
  secondDriverSeniority: '',
  secondPropClaimInYear: '',
  startDate: new Date(),
  street: '',
  streetCityId: '',
  streetCode: '',
  subModel: '',
  thirdDriverAge: '',
  thirdDriverBirthDate: null,
  thirdDriverSeniority: '',
  year: '',
  youngDriverAge: '',
  youngDriverBirthDate: null,
  youngDriverGender: '',
  youngDriverSeniority: '',
}, offersFields);

class SimulatorPage extends React.Component {
  constructor(props) {
    super(props);
    this.handler = this.handler.bind(this);
    this.checkStatus = this.checkStatus.bind(this);
    this.getOffers = this.getOffers.bind(this);
    this.selectOffer = this.selectOffer.bind(this);
    this.gotoCRM = this.gotoCRM.bind(this);
    this.setLicenseNumberValidation = this.setLicenseNumberValidation.bind(this);
    this.setStreetAndBuildingNumberValidation = this.setStreetAndBuildingNumberValidation.bind(this);
    this.updatePreferredOffers = this.updatePreferredOffers.bind(this);
    this.setCityHasStreets = this.setCityHasStreets.bind(this);
    this.setIsOffersValuesChanged = this.setIsOffersValuesChanged.bind(this);
    this.updatePotential = this.updatePotential.bind(this);
    this.processWithoutOffer = this.processWithoutOffer.bind(this);
    this.beforeRender();
    this.previousOfferIndex = -1;
    this.messages = this.messages.bind(this);
  }

  state = {
    agent: null,
    cityHasStreets: true,
    coversTitles: [],
    detailsStepState,
    isGotPotential: false,
    isNewPotential: false,
    isPotentialUpdated: false,
    isUpdatingProcessing: false,
    keepChecking: true,
    loader: false,
    lowestPrice: 0,
    offers: [],
    offersCallId: '',
    opportunityURL: null,
    simulatorErrorModalHeader: 'simulator.offer.modal_header',
    sortTypeId: 1,
  };

  messages(id, values) {
    return this.props.intl.formatMessage({id}, values);
  }

  beforeRender() {
    const parameters = queryString.parse(this.props.location.search);
    if (parameters.id) {
      getPotential({
        potential: parameters.id,
        token: parameters.token,
      }).then(resp => {
        if (resp.success && resp.data && resp.data.offersFields) {
          const autoInfo = resp.data.autoInfo;
          let oFields = resp.data.offersFields;
          oFields.isSaleInProgress = null;
          oFields.leadOwnerName = resp.data.offersFields.leadOwnerName;
          oFields.recordTypeDeveloperName = resp.data.offersFields.recordTypeDeveloperName;
          oFields.followupPhoneNumber = oFields.phoneNumber;
          oFields = Object.assign({insuranceHistory}, oFields);
          oFields.startDate = Moment(oFields.startDate).toISOString();
          oFields.endDate = Moment(oFields.startDate).add(1, 'year').subtract(1, 'month').endOf('month').toDate();
          if (oFields.driversCount === 'כל נהג') {
            oFields.driversCount = DRIVERS_COUNT.EVERY;
          }
          if (oFields.agentName) {
            const agent = resp.data.agent;
            const isFollowUpEnabled = agent?.isFollowUpEnabled || null;
            this.setState({
              agent: new Agent(oFields.agentName, oFields.agentName, agent?.displayName, isFollowUpEnabled),
            });
          } else {
            oFields.agentName = 'Wobi';
            this.setState({agent: new Agent('Wobi', 'Wobi', 'וובי סוכנות לביטוח', true)});
          }

          // TODO: this code is to make sure its not overriden by the cahced value

          if (oFields.youngDriverBirthDate) {
            oFields.youngDriverBirthDate = Moment(oFields.youngDriverBirthDate).toISOString();
          } else {
            oFields.youngDriverBirthDate = null;
          }

          if (oFields.mainDriverBirthDate) {
            oFields.mainDriverBirthDate = Moment(oFields.mainDriverBirthDate).toISOString();
          } else {
            oFields.mainDriverBirthDate = null;
          }

          if (oFields.secondDriverBirthDate) {
            oFields.secondDriverBirthDate = Moment(oFields.secondDriverBirthDate).toISOString();
          } else {
            oFields.secondDriverBirthDate = null;
          }

          if (oFields.thirdDriverBirthDate) {
            oFields.thirdDriverBirthDate = Moment(oFields.thirdDriverBirthDate).toISOString();
          } else {
            oFields.thirdDriverBirthDate = null;
          }

          oFields.insurance_1 = oFields.insCovers['1'] === '1' ?
            'FULLCOVER' : oFields.insCovers['1'] === '2' ? '3RDPARTY' : '0';
          oFields.insuranceHistory.insurance[0].type = oFields.insurance_1;
          oFields.insurance_2 = oFields.insCovers['2'] === '1' ?
            'FULLCOVER' : oFields.insCovers['2'] === '2' ? '3RDPARTY' : '0';
          oFields.insuranceHistory.insurance[1].type = oFields.insurance_2;
          oFields.insurance_3 = oFields.insCovers['3'] === '1' ?
            'FULLCOVER' : oFields.insCovers['3'] === '2' ? '3RDPARTY' : '0';
          oFields.insuranceHistory.insurance[2].type = oFields.insurance_3;
          oFields.insuranceHistory.claims.injury[0] = {amount: Number.parseInt(oFields.injuryClaims_1, 10),
            yearsPast: 1};
          oFields.insuranceHistory.claims.injury[1] = {amount: Number.parseInt(oFields.injuryClaims_2, 10),
            yearsPast: 2};
          oFields.insuranceHistory.claims.injury[2] = {amount: Number.parseInt(oFields.injuryClaims_3, 10),
            yearsPast: 3};
          oFields.insuranceHistory.claims.property[0] = {amount: Number.parseInt(oFields.propClaims_1, 10),
            yearsPast: 1};
          oFields.insuranceHistory.claims.property[1] = {amount: Number.parseInt(oFields.propClaims_2, 10),
            yearsPast: 2};
          oFields.insuranceHistory.claims.property[2] = {amount: Number.parseInt(oFields.propClaims_3, 10),
            yearsPast: 3};
          oFields.injuryClaims_1 = oFields.injuryClaims_1 || '';
          oFields.injuryClaims_2 = oFields.injuryClaims_2 || '';
          oFields.injuryClaims_3 = oFields.injuryClaims_3 || '';
          oFields.propClaims_1 = oFields.propClaims_1 || '';
          oFields.propClaims_2 = oFields.propClaims_2 || '';
          oFields.propClaims_3 = oFields.propClaims_3 || '';
          oFields.ldw = oFields.ldw ? 'yes' : 'no';
          oFields.fcw = oFields.fcw ? 'yes' : 'no';

          if (oFields.esp) {
            oFields.hasEspClientAnswer = oFields.esp ? 'yes' : oFields.esp !== null ? 'no' : null;
          }

          if (oFields.licenseNumber) {
            oFields.licenseNumber = String(oFields.licenseNumber);
          }
          offersFields.israeliId = offersFields.israeliId || '';
          oFields.esp = oFields.esp || oFields.hasEspClientAnswer ? 'yes' : oFields.esp !== null ? 'no' : null;
          oFields.haveAnotherCar = oFields.haveAnotherCar ? 'yes' : 'no';

          // in case of empty underwriting object (gender be undefined) saturdayDriving is 'yes'
          oFields.saturdayDriving = oFields.saturdayDriving ? 'yes' : oFields.gender === undefined ? 'yes' : 'no';
          oFields.pledged = oFields.pledged ? 'yes' : 'no';
          oFields.isYoungDriverIsMain = oFields.isYoungDriverIsMain ? 'yes' : 'no';
          oFields.street = oFields.street || oFields.pOwnerStreetTitle || '';
          oFields.buildingNumber = oFields.pOwnerBuildingNumber || '';
          setSimulatorOffersFields(oFields);

          // find and set 5 digit zip code
          if (oFields.city) {
            const initialCityObject = cities.find(item => item.name === oFields.city);
            if (initialCityObject) {
              oFields.cityCode = initialCityObject.code;
              oFields.cityPostCode = Number(initialCityObject.post_code);
              oFields.cityPostCode5 = Number(initialCityObject.post_code_5);
              setSimulatorOffersFields({
                cityCode: initialCityObject.code,
                cityPostCode: Number(initialCityObject.post_code),
                cityPostCode5: Number(initialCityObject.post_code_5),
              });
            }
          }

          if (autoInfo && Object.keys(autoInfo).length > 0) {
            autoInfo.esp = oFields.esp === 'yes' ? 1 : oFields.esp === 'no' ? 0 : null;
            oFields.brand = autoInfo.manufacturer_id;
            oFields.model = autoInfo.car_model;
            oFields.subModel = autoInfo.id.toString();
            oFields.leviModel = autoInfo.car_model;
            this.setLicenseNumberValidation(!autoInfo.active);
            setSimulatorOffersFields({autoInfo});
            this.setState({detailsStepState: Object.assign(detailsStepState, {...oFields, autoInfo})});
          } else {
            this.setState({detailsStepState: Object.assign(detailsStepState, {...oFields})});
          }

          this.setState({isNewPotential: ['New', 'חדש'].includes(resp.data.requiredPolicyType)});
          this.setState({isGotPotential: true});
        }
      }).catch((error) => {
        if (error.message.includes('Salesforce authorization token is missing or expired')) {
          this.setState({
            isPotentialUpdated: true,
            simulatorErrorModalHeader: 'simulator.error.token_missed',
          });
        }
      });
    } else {
      alert(this.messages('simulator.error.open.direct'));
    }
  }

  getTitles() {
    getCoversTitles(getSimulatorOffersFields('policyType')).then((resp) => {
      if (resp && resp.data) {
        this.setState({coversTitles: resp.data.coversTitles});
      }
    });
  }

  async handler(actionType, values) {
    switch (actionType) {
    case 'sort': {
      const sortTypeId = values;
      const comparer = sortingOptions.find(sortingOption => sortingOption.id === sortTypeId).comparer;
      this.setState({
        offers: [...this.state.offers.sort(comparer)],
        sortTypeId,
      });
      break;
    }
    default:
      break;
    }
  }

  checkStatus() {
    checkOfferStatus({
      offersCallId: this.state.offersCallId,
    }).then(resp => {
      if (resp.data && resp.data.responses && resp.data.responses.length) {
        const respOffers = resp.data.responses;
        const getPrice = (max, price) => max ? max : price;
        const pickLowest = (price, lowestPrice) => price < lowestPrice ? price : lowestPrice;
        this.setState({
          lowestPrice: respOffers
            .reduce((lowestPrice, {price, maximumPrice}) => !lowestPrice ? getPrice(maximumPrice, price) :
              maximumPrice ? pickLowest(maximumPrice, lowestPrice) : pickLowest(price, lowestPrice), 0),
          offers: respOffers,
        }, () => {
          this.handler('sort', this.state.sortTypeId);
        });
      }
    });
  }

  clearTimer() {
    this.setState({
      keepChecking: false,
      loader: false,
    });
    clearInterval(this.statusInterval);
  }

  startLoader(callback) {
    this.setState({
      keepChecking: true,
      loader: true,
    }, callback);
  }

  initTimer() {
    this.startLoader(() => {
      this.setStreetAndBuildingNumberValidation();
      this.statusInterval = setInterval(() => {
        if (this.state.keepChecking) {
          this.checkStatus();
        } else {
          this.clearTimer();
        }
      }, CHECK_STATUS_TIMEOUT);
      this.timeout = setTimeout(() => {
        this.clearTimer();
      }, CHECK_STATUS_TIME_LIMIT);
    });
  }

  gotoCRM(gotoCRM) {
    if (!gotoCRM) {
      this.setState({isPotentialUpdated: false});
      return;
    }

    const {opportunityURL} = this.state;
    if (!opportunityURL) {
      const parameters = queryString.parse(this.props.location.search);
      window.location.href = `${process.env.REACT_APP_CRM_URL}/${parameters.id}`;
    } else {
      window.location.href = opportunityURL; // url ? url : `${process.env.REACT_APP_CRM_URL}/${parameters.id}`;
    }
  }

  compareDates(dateTimeA, dateTimeB) {
    const momentA = Moment(dateTimeA, 'DD/MM/YYYY');
    const momentB = Moment(dateTimeB, 'DD/MM/YYYY');
    return momentA.isBefore(momentB);
  }

  setStreetAndBuildingNumberValidation() {
  // const {isNewPotential, cityHasStreets} = this.state;
    this.setState({streetAndBuildingValidation: {}});

    /*
    if (isNewPotential && cityHasStreets) {
      this.setState({streetAndBuildingValidation: {
        buildingNumber: yup.string().required('שדה חובה'),
        street: yup.string().required('שדה חובה'),
      }});
    } else {
      this.setState({streetAndBuildingValidation: {}});
    }
    */
  }

  setCityHasStreets(hasStreets) {
    this.setState({cityHasStreets: hasStreets});
  }

  setLicenseNumberValidation(isInactive) {
    this.setState({licenseNumberValidation: isInactive ?
      {licenseNumber: yup.number().typeError('שדה חובה').required('שדה חובה')} : {}});
  }

  prepateRequestData() {
    const requestData = mapSimulatorRequestData(getSimulatorOffersFields());
    if (requestData.secondDriverSeniority && requestData.youngDriverSeniority &&
      requestData.driversCount === DRIVERS_COUNT.TWO) {
      const secondDriverSeniority = Number.parseInt(requestData.secondDriverSeniority, 10);
      const youngDriverSeniority = Number.parseInt(requestData.youngDriverSeniority, 10);
      if (!Number.isNaN(secondDriverSeniority) && !Number.isNaN(youngDriverSeniority) &&
          youngDriverSeniority > secondDriverSeniority) {
        requestData.youngDriverSeniority = requestData.secondDriverSeniority;
      }
      if (this.compareDates(requestData.youngDriverBirthDate, requestData.secondDriverBirthDate)) {
        requestData.youngDriverBirthDate = requestData.secondDriverBirthDate;
        requestData.youngDriverAge = requestData.secondDriverAge;
      }
    } else if (requestData.mainDriverSeniority && requestData.youngDriverSeniority &&
        requestData.driversCount === DRIVERS_COUNT.EVERY) {
      const mainDriverSeniority = Number.parseInt(requestData.mainDriverSeniority, 10);
      const youngDriverSeniority = Number.parseInt(requestData.youngDriverSeniority, 10);
      if (!Number.isNaN(mainDriverSeniority) && !Number.isNaN(youngDriverSeniority) &&
        !requestData.isYoungDriverIsMain && youngDriverSeniority > mainDriverSeniority) {
        requestData.youngDriverSeniority = requestData.mainDriverSeniority;
      }
      if (this.compareDates(requestData.youngDriverBirthDate, requestData.mainDriverBirthDate) &&
        !requestData.isYoungDriverIsMain) {
        requestData.youngDriverBirthDate = requestData.mainDriverBirthDate;
        requestData.youngDriverAge = requestData.mainDriverAge;
      }
    }
    return requestData;
  }

  getOffers(parametersId, selectedOffer) {
    const requestData = this.prepateRequestData();
    if (selectedOffer) {
      requestData.productLineId = selectedOffer.productLineId;
      getAllOffers(requestData).then(resp => {
        if (resp.data) {
          this.updatePotential(parametersId, selectedOffer, this.state.offers, resp.data.offersCallId);
        }
        this.clearTimer();
      }).catch(() => {
        this.clearTimer();
      });
    } else {
      this.getTitles();
      this.setState({keepChecking: true,
        offers: []});
      this.setState({offers: []}, () => {
        this.startLoader();
        getAllOffers(requestData).then(resp => {
          if (resp.data) {
            const offerCallIdState = {offersCallId: resp.data.offersCallId};
            setSimulatorOffersFields(offerCallIdState);
            this.setState(offerCallIdState);
          }
          this.initTimer();
        }).catch(() => {
          this.clearTimer();
        });
      });
    }
  }

  processWithoutOffer() {
    const parameters = queryString.parse(this.props.location.search);
    this.updatePotential(parameters.id, null, null);
  }

  selectOffer(offer, index) {
    const parameters = queryString.parse(this.props.location.search);
    const updateOffersState = (indexToUpdate, isChanged = false) => ({offers}) => ({
      offers: [
        ...offers.slice(0, indexToUpdate),
        {
          ...offers[indexToUpdate],
          isChanged,
        },
        ...offers.slice(indexToUpdate + 1),
      ],
    });

    if (this.previousOfferIndex !== -1) {
      this.setState(
        updateOffersState(this.previousOfferIndex),
      );
    }
    this.previousOfferIndex = index;
    this.setState(
      updateOffersState(index, this.isOffersValuesChanged),
      () => {
        if (this.isOffersValuesChanged) {
          return;
        }
        if (offer.provider === 'FNX') {
          this.setState({isUpdatingProcessing: true});
          this.getOffers(parameters.id, offer);
        } else {
          this.updatePotential(parameters.id, offer, this.state.offers);
        }
      },
    );
  }

  updatePotential(id, offer, offers, offersCallId) {
    if (id) {
      const parameters = queryString.parse(this.props.location.search);
      this.setState({isUpdatingProcessing: true});
      const potentialData = mapSimulatorRequestData(getSimulatorOffersFields(), true);
      potentialData.model = potentialData.subModel.slice(0, -4);
      potentialData.hasEspClientAnswer = potentialData.hasEspClientAnswer ?
        potentialData.hasEspClientAnswer : potentialData.esp;
      let offersToPass = offers;
      let createMockPriceQuotes = false;

      if (offersToPass === null) {
        offersToPass = [];
        createMockPriceQuotes = true;
      }

      updatePotential({
        createMockPriceQuotes,
        offers: offersToPass,
        offersCallId,
        potentialData,
        potentialId: id,
        selectedOffer: offer,
        token: parameters.token,
      }).then(response => {
        this.setState({isUpdatingProcessing: false});
        if (response.success) {
          const url = response?.redirectUrl || null;

          // TODO: need to set URL into state and show modal for redirecting user to CRM with the URL
          console.log(url, 'setting url');
          this.setState({isPotentialUpdated: true, opportunityURL: url},
            () => console.log('updatePotential isPotentialUpdated:', this.state.isPotentialUpdated));
        } else if (response?.errorMessage) {
          alert(JSON.stringify(response.errorMessage));
        }
      }).catch((error) => {
        if (error.message.includes('Salesforce authorization token is missing or expired')) {
          this.setState({
            isPotentialUpdated: true,
            isUpdatingProcessing: false,
            simulatorErrorModalHeader: 'simulator.error.token_missed',
          });
        } else {
          this.setState({
            isPotentialUpdated: true,
            isUpdatingProcessing: false,
          });
        }
      });
    }
  }

  updatePreferredOffers(preferredOffers) {
    const parameters = queryString.parse(this.props.location.search);
    if (parameters.id && Object.keys(preferredOffers).length) {
      this.setState({isUpdatingProcessing: true});
      const potentialData = mapSimulatorRequestData(getSimulatorOffersFields());
      updatePreferredOffers({
        potentialData,
        potentialId: parameters.id,
        preferredOffers,
      }).then(response => {
        this.setState({isUpdatingProcessing: false});
        if (response.success) {
          this.setState({isPotentialUpdated: true});
        }
      }).catch((error) => {
        alert(JSON.stringify(error));
        this.setState({isUpdatingProcessing: false});
      });
    }
  }

  setIsOffersValuesChanged(isOffersValuesChanged) {
    this.isOffersValuesChanged = isOffersValuesChanged;
  }

  render() {
    return (
      this.state.isGotPotential ?
        <Formik
          validationSchema={yup.object().shape({
            ...simulatorValidations,
            ...this.state.streetAndBuildingValidation,
            ...this.state.licenseNumberValidation,
          })}
          initialValues={this.state.detailsStepState}>
          {({values, handleChange, setFieldValue, validateForm, resetForm, setFieldTouched}) => (
            <Form>
              <SimulatorLayout
                formikValidateFrom={validateForm}
                formikHandleChange={handleChange}
                formikSetField={setFieldValue}
                formikSetTouched={setFieldTouched}
                formikResetForm={resetForm}
                formikValues={values}
                submitCallback={this.getOffers}
                updatePreferredCallback={this.updatePreferredOffers}
                onSelectOffer={this.selectOffer}
                processWithoutOffer={this.processWithoutOffer}
                offers={this.state.offers}
                openModal={this.state.isPotentialUpdated}
                modalHeader={this.state.simulatorErrorModalHeader}
                updating={this.state.isUpdatingProcessing}
                isUpdated={this.state.isPotentialUpdated}
                closeModalCallback={this.gotoCRM}
                setCityHasStreets={this.setCityHasStreets}
                currentStreetAndBuildingValidation={this.state.streetAndBuildingValidation}
                setLicenseNumberValidationCallback={this.setLicenseNumberValidation}
                currentLicenseNumberValidation={this.state.licenseNumberValidation}
                coversTitles={this.state.coversTitles}
                loaderState={this.state.loader}
                lowestPrice={this.state.lowestPrice}
                isNewPotential={this.state.isNewPotential}
                setIsOffersValuesChanged={this.setIsOffersValuesChanged}
                followupCallRandomDateTime={this.state.detailsStepState.followupCallRandomDateTime}
                recordTypeDeveloperName={this.state.detailsStepState.recordTypeDeveloperName}
                agent={this.state.agent}
              />
            </Form>
          )}
        </Formik> : false
    );
  }
}

SimulatorPage.propTypes = {
  history: PropTypes.object,
  intl: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(injectIntl(SimulatorPage));
