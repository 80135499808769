import {makeStyles} from '@material-ui/core/styles';
import {Breakpoints as breakpoints} from 'wobi-web-common';
import {agentDeveloperUtils} from 'wobi-web-common/dist/components/utils/helpers';

const useStyles = makeStyles(theme => ({
  allPricesWrapDiscount: {
    '@media screen and (max-width: 1097px)': {
      maxWidth: '100%',
    },
    position: 'relative',
  },
  creditCardCommission: {
    display: 'block',
  },
  includedText: {
    '&:before': {
      backgroundColor: '#B8D1DE',
      borderRadius: 6,
      content: '""',
      display: 'block',
      height: 6,
      marginRight: 6,
      minWidth: 6,
      width: 6,
    },
    '&:first-child': {
      bottom: 50,
      letterSpacing: '-0.4px',
      marginTop: 20,
    },
    '@media screen and (max-width: 1097px)': {
      margin: '7px 0',
      position: 'static',
    },
    alignItems: 'center',
    bottom: 25,
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 500,
    letterSpacing: '0.3px',
    lineHeight: '14px',
    position: 'absolute',
  },
  includedWrapper: {
    '&.open': {
      margin: '0 auto',
    },
    '@media screen and (max-width: 1097px)': {
      flexBasis: '100%',
      height: 'auto',
      marginRight: 8,
      position: 'static',
    },
    height: 114,
    maxWidth: 225,
    position: 'relative',
  },
  left: {
    '@media screen and (max-width: 420px)': {
      '& > div > div': {
        '&:first-child': {
          width: '40%',
        },
        '&:last-child': {
          width: 'calc(50% - 8px)',
        },
      },
    },
    '@media screen and (max-width: 1023px)': {
      marginTop: 10,
    },
    marginLeft: 10,
    marginTop: 30,
    maxWidth: 600,
    minWidth: 0,
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      '& > div': {
        height: 'inherit',
      },
      '& > div > div': {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 0,
        width: '50%',
      },
      margin: 0,
      maxWidth: '100%',
    },
    width: '100%',
  },
  leftClosed: {
    '@media screen and (max-width: 420px)': {
      marginTop: 0,
      paddingRight: 0,
    },
    '@media screen and (max-width: 1023px)': {
      marginTop: 30,
    },
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      height: 67,
    },
    paddingRight: 16,
  },
  logo: {
    '& > b': {
      color: theme.palette.secondary.dark,
      direction: 'rtl',
      fontSize: theme.typography.pxToRem(12.25),
      fontWeight: 700,
      lineHeight: '14px',
      padding: '0 3px',
      textAlign: 'end',
    },
    '& > img': {
      display: 'inline-block',
      width: 'inherit',
    },
    '@media screen and (max-width: 420px)': {
      '& > img': {
        width: 88,
      },
      height: 67,
      justifyContent: 'flex-end',
      minWidth: 88,
      width: '40%',
    },
    '@media screen and (max-width: 825px) and (min-width: 800px)': {
      width: 120,
    },
    '@media screen and (max-width: 1023px)': {
      marginTop: 17,
    },
    display: 'flex',
    flexFlow: 'column nowrap',
    height: 100,
    justifyContent: 'center',
    [`@media screen and (max-width: 799px) and (min-width: ${breakpoints.mobile + 1}px)`]: {
      width: 100,
    },
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      height: 'unset',
      justifyContent: 'flex-end',
      marginTop: 0,
    },
    width: 150,
  },
  logoClosed: {
    '& > b': {
      '@media screen and (max-width: 420px)': {
        position: 'relative',
        whiteSpace: 'pre-wrap',
        width: 'min-content',
      },
    },
    marginTop: 8,
  },
  offerContent: {
    '@media screen and (max-width: 420px)': {
      padding: '0 10px 10px',
    },
    '@media screen and (max-width: 768px)': {
      padding: '20px 0 10px',
    },
    alignItems: 'flex-start',
    display: 'flex',
    outline: 'none',
    padding: '20px 20px 20px 35px',
  },
  offerContentClosed: {
    cursor: 'pointer',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      justifyContent: 'space-around',
      padding: '10px 10px 14px 14px',
    },
  },
  partialPrice: {
    '&:first-child': {
      '&:after': {
        '@media screen and (max-width: 420px)': {
          marginLeft: 4,
        },
        color: theme.palette.text.secondary,
        content: '|',
        display: 'inline-block',
        fontSize: theme.typography.fontSize,
        fontWeight: 200,
        marginLeft: 10,
        opacity: 0.9,
      },
      '@media screen and (max-width: 420px)': {
        marginRight: 4,
      },
      marginRight: 10,
    },
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12.25),
    fontWeight: 500,
  },
  partialPriceDiscount: {
    '@media screen and (max-width: 420px)': {
      fontSize: theme.typography.pxToRem(8.62),
      marginLeft: '5px',
    },
    fontSize: theme.typography.pxToRem(9.62),
  },
  partialPrices: {
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      height: 14,
      lineHeight: '14px',
    },
    '@media screen and (max-width: 420px)': {
      fontSize: theme.typography.pxToRem(9.62),
    },
  },
  partition: {
    '@media screen and (max-width: 420px)': {
      display: 'none',
    },
    '@media screen and (max-width: 768px)': {
      backgroundColor: agentDeveloperUtils.getConvertedColor('#D8EAF1'),
      bottom: 0,
      display: 'block',
      height: 65,
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-1px)',
      width: 2,
    },
  },
  prices: {
    '@media screen and (max-width: 420px)': {
      alignItems: 'center',
      minWidth: 140,
      width: 'inherit',
    },
    '@media screen and (max-width: 1023px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    '@media screen and (max-width: 1097px)': {
      marginLeft: 20,
    },
    alignItems: 'flex-start',
    display: 'flex',
    flexFlow: 'column nowrap',
    marginLeft: 33,
    minWidth: 191,
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      alignItems: 'center',
      flex: 0,
      height: 'unset',
      justifyContent: 'flex-end',
      minWidth: 150,
      width: 150,
    },
    position: 'relative',
  },

  pricesClosed: {
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      justifyContent: 'center',
      position: 'static',
    },
    '@media screen and (max-width: 420px)': {
      minWidth: 70,
    },
    '@media screen and (max-width: 500px)': {
      justifyContent: 'flex-start',
    },
    '@media screen and (max-width: 830px)': {
      marginLeft: 0,
      minWidth: 100,
    },
  },
  pricesWrap: {
    '@media screen and (max-width: 420px)': {
      justifyContent: props => props.isOpen ? '' : 'center',
    },
    display: 'flex',
  },
  pricesWrapDiscount: {
    '@media screen and (max-width: 420px)': {
      justifyContent: props => props.isOpen ? '' : '',
    },
    '@media screen and (max-width: 1097px)': {
      display: 'block',
    },
    display: 'flex',
  },
  protection: {
    '&:before': {
      color: agentDeveloperUtils.getPrimaryColor(450),
      content: '"*"',
      fontSize: theme.typography.pxToRem(16.6),
      left: -1,
      position: 'absolute',
      top: -1,
    },
    paddingLeft: 10,
    position: 'relative',
  },
  protectionWrapper: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(11.4),
    fontWeight: 600,
    lineHeight: 1,
    marginTop: 10,
  },
  protectionWrapperOpen: {
    justifyContent: 'center',
  },
  purchaseBtn: {
    '@media screen and (max-width: 420px)': {
      fontSize: theme.typography.pxToRem(12.25),
      marginTop: 16,
      minWidth: 70,
      padding: '7px 10px',
      width: '100%',
    },
    '@media screen and (max-width: 1023px)': {
      minWidth: 'unset',
      width: '100%',
    },
    fontSize: theme.typography.fontSize,
    fontWeight: 700,
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      maxWidth: 191,
      minWidth: 70,
      width: 'calc(100% - 10px)',
    },
    minWidth: 191,
  },
  purchaseBtnWrapper: {
    '@media screen and (max-width: 420px)': {
      marginLeft: 8,
    },
    '@media screen and (max-width: 1023px)': {
      flex: '0 1 auto',
      marginLeft: 16,
      width: 'calc(50% - 16px)',
    },
    alignItems: 'flex-end',
    display: 'flex',
    flex: '0 1 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      justifyContent: 'flex-end',
    },
    width: '191px',
  },
  totalPrice: {
    '& > span': {
      fontSize: theme.typography.pxToRem(21),
      marginLeft: 5,
    },
    '@media screen and (max-width: 420px)': {
      fontSize: theme.typography.pxToRem(31.5),
    },
    color: agentDeveloperUtils.getPrimaryColor(450),
    fontSize: theme.typography.pxToRem(38.5),
    fontWeight: 700,
    lineHeight: 1,
    marginBottom: 5,
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      marginBottom: 0,
    },
    marginTop: 21,
    transition: 'all 0.3s',
  },
  totalPriceClosed: {
    '@media screen and (max-width: 420px)': {
      '& > span': {
        fontSize: theme.typography.fontSize,
      },
      fontSize: theme.typography.fontSize,
      marginTop: 28,
    },
    '@media screen and (max-width: 500px)': {
      position: 'static',
      transform: 'none',
    },
    '@media screen and (max-width: 1023px)': {
      fontSize: theme.typography.pxToRem(28),
    },
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      fontSize: theme.typography.pxToRem(24.5),
      marginTop: 0,
      position: 'absolute',
      right: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    color: theme.palette.type === 'light' ? '#595959' : theme.palette.text.primary,
    marginTop: 0,
  },
  totalPriceClosedDiscount: {
    '@media screen and (max-width: 420px)': {
      '& > span': {
        fontSize: theme.typography.fontSize,
      },
      fontSize: theme.typography.fontSize,
      marginTop: 28,
    },
    '@media screen and (max-width: 500px)': {
      position: 'static',
      transform: 'none',
    },
    '@media screen and (max-width: 1023px)': {
      fontSize: theme.typography.pxToRem(28),
    },
    [`@media screen and (max-width: ${breakpoints.mobile}px)`]: {
      fontSize: theme.typography.pxToRem(24.5),
      marginTop: 0,
      position: 'absolute',
      right: '0%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    color: theme.palette.type === 'light' ? '#595959' : theme.palette.text.primary,
    marginTop: 0,
  },
  totalPriceCrossedClosed: {
    '&:before': {
      borderBottom: '3px solid red',
      content: '""',
      height: '50%',
      position: 'absolute',
      transform: 'rotate(-25deg)',
      width: '100%',
    },
    '@media screen and (max-width: 420px)': {
      fontSize: theme.typography.pxToRem(20),
      marginLeft: '0px',
      marginTop: '35px',
    },
    fontSize: theme.typography.pxToRem(25.5),
    marginLeft: '10px',
    marginTop: '10px',
    position: 'relative',
    textDecoration: 'none',
  },
  totalPriceCrossedOpen: {
    '&:before': {
      '@media screen and (max-width: 420px)': {
        left: '0px',
      },
      '@media screen and (min-width: 1098px)': {
        height: '33%',
        width: '61%',
      },
      borderBottom: '3px solid red',
      content: '""',
      height: '50%',
      left: '-50px',
      position: 'absolute',
      top: '10px',
      transform: 'rotate(-25deg)',
      width: '80%',
    },
    '@media screen and (max-width: 420px)': {
      fontSize: theme.typography.pxToRem(20),
      left: '0px',
    },
    fontSize: theme.typography.pxToRem(25.5),
    left: '50px',
    marginTop: '21px',
    position: 'relative',
    textDecoration: 'none',

  },
  totalPriceDiscount: {
    '@media screen and (max-width: 420px)': {
      fontSize: theme.typography.pxToRem(21.5),
    },
    '@media screen and (min-width: 1098px)': {
      fontSize: '1rem',
      marginLeft: '-10px',
    },
    fontSize: theme.typography.pxToRem(21.5),
    minWidth: '90px',
  },

}));

export default useStyles;
