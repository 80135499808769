import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, CircularProgress, IconButton, Slide} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {FormattedMessage} from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {Breakpoints as breakpoints} from 'wobi-web-common';
import {handleContactUsSubmit} from '../utils/apiHandlers';
import {getOffersFields} from '../utils/persistOfferDetails';
import {
  updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields,
  updateForceTriggeredByInSessionStorageOffersFields,
} from '../utils/common';
import {TRIGGERED_BY, abandonmentStep} from '../constants/enums';
import {getImageByAgent} from '../config/agent';

const TelephoneIcon = getImageByAgent('telephone.svg');
const daniela = getImageByAgent('daniela.png');

const CALLME_FOOTER_DELAY = process.env.REACT_APP_CALLME_FOOTER_DELAY || 2000;

const useStyles = makeStyles(theme => ({
  callUs: {
    '&:active': {
      backgroundColor: theme.palette.background.paper,
    },
    '&:before': {
      borderBottom: '7px solid transparent',
      borderLeft: `7px solid ${theme.palette.background.paper}`,
      borderTop: '7px solid transparent',
      content: '""',
      position: 'absolute',
      right: -6,
      top: 12,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'unset',
    },
    '@media screen and (max-width: 359px)': {
      fontSize: theme.typography.pxToRem(11),
      minWidth: 184,
    },
    '@media screen and (min-width: 100px) and (max-width: 327px)': {
      minWidth: 178,
      padding: '5px 8px',
    },
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    boxShadow: 'unset',
    color: theme.palette.text.primary,
    display: 'flex',
    fontSize: theme.typography.pxToRem(13.125),
    justifyContent: 'flex-start',
    lineHeight: '26.25px',
    margin: 0,
    minWidth: 214,
    padding: '5px 10px',
    position: 'absolute',
    right: 100,
    top: 7,
  },
  callUsStrong: {
    color: theme.palette.secondary.dark,
    fontWeight: 700,
    margin: '0 6px',
  },
  callUsUnderline: {
    color: theme.palette.secondary.dark,
    textDecoration: 'underline',
  },
  centerText: {
    textAlign: 'center',
    width: '100%',
  },
  closeBtn: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '@media screen and (max-width: 327px)': {
      left: 8,
    },
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    left: 10,
    padding: 1,
    position: 'absolute',
    top: 12,
  },
  container: {
    background: 'linear-gradient(#5199d0, #2371a0)',
    bottom: 0,
    boxShadow: '0px -2px 20px 0 rgb(0 0 0 / 40%)',
    height: 50,
    left: 0,
    position: 'fixed',
    width: '100%',
    zIndex: 8,
  },
  danielaMobile: {
    '& img': {
      width: 100,
    },
    flex: '0 0 100px',
    marginBottom: -25,
  },
  danielaMobileOpen: {
    bottom: 0,
    position: 'absolute',
    right: 0,
  },
  loaderContainer: {
    height: 25,
    textAlign: 'center',
    width: '100%',
  },
}));

const CallMeFooter = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isContactMeSubmitted, setContactMeSubmitted] = React.useState(false);
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width:${breakpoints.mobile}px)`);
  const submitContactUs = () => {
    if (!isSubmitting) {
      updateForceAbandonmentStepForNewLeadsInSessionStorageOffersFields(abandonmentStep.ABANDONMENT_STEP_3.key);
      updateForceTriggeredByInSessionStorageOffersFields(TRIGGERED_BY.WEBSITE_INSIDE_OFFER);
      setIsSubmitting(true);
      handleContactUsSubmit({
        fullname: `${getOffersFields('firstname')} ${getOffersFields('lastname')}`,
        offersCallId: getOffersFields('offersCallId'),
        phone: getOffersFields('phone'),
      }, getOffersFields(), TRIGGERED_BY.WEBSITE_INSIDE_OFFER)
        .then(() => {
          setContactMeSubmitted(true);
          setIsSubmitting(false);
          return true;
        })
        .catch(error => {
          console.log(error);
          setIsSubmitting(false);
        });
    }
  };

  React.useEffect(() => {
    if (isContactMeSubmitted) {
      setTimeout(() => {
        setContactMeSubmitted(false);
      }, 5000);
    }
  }, [isContactMeSubmitted]);

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, CALLME_FOOTER_DELAY);
  }, []);

  return isMobile && isOpen ? (
    <Slide direction='up' in={isOpen} mountOnEnter unmountOnExit
      easing={{enter: 'cubic-bezier(0.4, 0, 0.2, 1)'}}>
      <nav className={classes.container}>
        <div className={`${classes.danielaMobile} ${classes.danielaMobileOpen}`}>
          <img src={daniela} alt='daniela' />
        </div>
        {isContactMeSubmitted ? (
          <span className={classes.callUs}>
            <span className={classes.centerText}>
              <FormattedMessage id='offers.footer_call_me_submitted' />
            </span>
          </span>
        ) : (
          <Button className={classes.callUs} onClick={submitContactUs}>
            <img src={TelephoneIcon} alt='call-us'/>
            {isSubmitting ? (
              <span className={classes.loaderContainer}>
                <CircularProgress size={25}/>
              </span>
            ) : (
              <>
                <strong className={classes.callUsStrong}>
                  <FormattedMessage id='offers.debating'/>
                </strong>
                <span className={classes.callUsUnderline}>
                  <FormattedMessage id='offers.footer_call_me1' />
                </span>
              </>
            )}
          </Button>
        )}
        <IconButton className={classes.closeBtn} onClick={() => setIsOpen(false)}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </nav>
    </Slide>
  ) : null;
};

export default CallMeFooter;
